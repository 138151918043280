import React from "react";
import PropTypes from "prop-types";
import useLocalQuery, { Table, NonSortableHeader } from "hooks/useQuery/local-query";
import AWSVmailRecording from "./vmail-recording";

const CallRecordingSearchResults = ({
  callrecordings,
  isFetching
}) => {
  const { rows, query, queryDispatcher } = useLocalQuery(callrecordings, {});
  return (
    <Table
      isFetching={isFetching}
      containerClass="query-table callrecording-search-results-table"
      scrollable
      TableRow={({
        timestamp,
        call_sid,
        parent_call_sid,
        client_id,
        from_phone,
        to_phone,
        call_direction        
      }) => (
        <tr>
          <td>{timestamp}</td>
          <td>{call_sid}</td>
          <td>{parent_call_sid}</td>
          <td>{client_id}</td>
          <td>{from_phone}</td>
          <td>{to_phone}</td>
          <td>{call_direction}</td>
          <td style={{ padding: 0}}>
            <AWSVmailRecording id={parent_call_sid} />
          </td>
        </tr>
      )}
      columns={[
        {
          header: "Call Time",
          field: "timestamp",
          Cell: () => <NonSortableHeader text="Call Time" style={{ width: 80, maxWidth: 80 }} />
        },
        {
          header: "Call Sid",
          field: "call_sid",
          Cell: () => <NonSortableHeader text="Call Sid" style={{ width: 100, maxWidth: 100 }} />
        },
        {
          header: "Parent Call Sid",
          field: "parent_call_sid",
          Cell: () => <NonSortableHeader text="Parent Call Sid" style={{ width: 100, maxWidth: 100 }} />
        },
        {
          header: "Client Id",
          field: "client_id",
          Cell: () => (
            <NonSortableHeader text="Client Id" style={{ width: 100, maxWidth: 100 }} />
          ),
          style: { width: 120, maxWidth: 120 }
        },
        {
          header: "From Phone",
          field: "from_phone",
          Cell: () => (
            <NonSortableHeader text="From Phone" style={{ width: 50, maxWidth: 50 }} />
          ),
          style: { width: 120, maxWidth: 120 }
        },
        {
          header: "To Phone",
          field: "to_phone",
          Cell: () => <NonSortableHeader text="To Phone" style={{ width: 50, maxWidth: 50 }} />
        },
        {
          header: "Call Direction",
          field: "call_direction",
          Cell: () => <NonSortableHeader text="Call Direction" style={{ width: 50, maxWidth: 50 }} />
        },
        {
          header: 'Audio',
          Cell: () => <NonSortableHeader text="Audio" style={{ width: 40, maxWidth: 40 }} />
        },
      ]}
      query={query}
      queryDispatcher={queryDispatcher}
      rows={rows}
      rowKey="id"
    />
  );
};

export default CallRecordingSearchResults;

CallRecordingSearchResults.propTypes = {
  callrecordings: PropTypes.arrayOf(PropTypes.object),
  isFetching: PropTypes.bool
};
