import * as Yup from "yup";
import { uniq } from "lodash";
import { isRequired } from "utils/redux-form";

const QuestionValidation = {
  office_pickup: isRequired,
  change_in_supplies: isRequired,
  outside_purchase: isRequired,
  hospice_care: isRequired,
  pap_issues: isRequired,
  person_ordering: isRequired,
  caregiver_name: isRequired,
  nights_per_week: isRequired,
  hours_per_night: isRequired,
  last_seen_physician: isRequired,
  outside_supplies: isRequired,
  quantity_on_hand: isRequired,
  send_last_mask: isRequired,
  implantable_device: isRequired
};

export const questionValidation = id => {
  const validationFn = QuestionValidation[id];
  if (typeof validationFn == "function") return validationFn;
};

export const getEqMissingDysfunctions = (values, questions) => {
  const requested_equipment = uniq(
    values.requested_equipment.map(x => {
      if (x.includes("filter")) return "filter"; // treat disp/non disp as same
      if (x.includes("tubing")) return "tubing"; // treat heated/non heated tubing as the same
      return x.toLowerCase();
    })
  );

  const dyfunctionsToAsk =
    questions.Dysfunctions?.filter(({ productType }) =>
      requested_equipment.includes(productType.toLowerCase())
    ) ?? [];

  if (dyfunctionsToAsk.length == 0) return true;

  let eqRequiringDysfunctions = [];

  requested_equipment.forEach(eq => {
    var eqDysfunctionQuestions = dyfunctionsToAsk.filter(
      x => x.productType.toLowerCase() == eq
    );
    // require atleast one dysfunction for each requested equipment
    if (!eqDysfunctionQuestions.some(x => values.questions[x.id]) == true) {
      eqRequiringDysfunctions = [...eqRequiringDysfunctions, eq];
    }
  });

  return eqRequiringDysfunctions;
};

export const getIsValidDysfunctionAnswers = (values, questions) => {
  if (!(values.insurances[0]?.requires_dysfunctions ?? true)) {
    return true;
  }

  const eqRequiringDysfunctions = getEqMissingDysfunctions(values, questions);

  if (eqRequiringDysfunctions.length > 0) return false;
  else return true;
};

const OrderFormValidationSchema = questions =>
  Yup.lazy(values =>
    Yup.object().shape({
      requested_equipment: Yup.array()
        .min(1, "Please choose an item")
        .required(),
      // physician: Yup.object().shape({
      //   name: Yup.string() // required if asked
      // }),
      insurances: Yup.array().of(
        Yup.object().shape({
          insurance_company_id: Yup.string().required(
            "Please select an Insurance"
          )
        })
      ),
      address: Yup.object().shape({
        street_address: Yup.string().required(),
        city: Yup.string().required(),
        state: Yup.string().required(),
        zip: Yup.string().required()
      }),
      dysfunctions: Yup.string().test("dysfunctions", "Required", () =>
        getIsValidDysfunctionAnswers(values, questions)
      )
    })
  );

export default OrderFormValidationSchema;
