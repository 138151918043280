import Export from "components/screens/exports/exports";
import PhoneQueues from "components/screens/phone-queues";
import PhoneBook from "components/screens/phone-book";
import Support from "components/screens/support/support";
import GoogleDriveFiles from "features/google-api/components/drive-files";
import MyProfile from "components/screens/my-profile";
import InventoryTable from "components/screens/inventory/inventory-table";
import FulfillmentInventoryProfile from "components/screens/fulfillment-inventory/fulfillment-inventory-profile/index";
import FulfillmentInventoryTable from "components/screens/fulfillment-inventory/fulfillment-inventory-table/index";
import FulfillmentInventoryProvider from "../components/screens/fulfillment-inventory/fulfillment-inventory-provider";
import InventoryProvider from "components/screens/inventory/inventory-provider";
import InventoryImportForm from "components/screens/inventory/inventory-import/inventory-import";
import InventoryProfile from "components/screens/inventory/inventory-profile";
import ManageInventory from "components/screens/inventory/manage-inventory";
import IVRWorkTableContainer from "components/ivr-control-center/ivr-work-table-container";
import VoicemailContainer from "components/screens/vmail-table/voicemailContainer";
import CallRecordingContainer from "components/screens/callrecording/callrecordingContainer";
import VMailWorkPage from "components/screens/vmail-profile/vmail-work-page";
import VoicemailsTable from "components/voicemails-table";
import VoicemailProfile from "components/voicemail-profile";
import ChatsContainer from "components/screens/chats";
import Email from "components/screens/email";
import PdfSignatures from "components/pdf-signatures";
import Tasks from "components/tasks";
import Cmns from "components/cmns";
import Questionnaires from "components/questionnaires";
import SynthpopIntegrationTesting from "features/synthpop";
import React from "react";
import { withRouter } from "react-router-dom";

export default [
  {
    path: "/synthpop",
    component: SynthpopIntegrationTesting,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/phones/queues",
    component: PhoneQueues,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "ServiceCoach",
      "ServiceAdmin",
      "ContractorAdministrator"
    ]
  },
  {
    path: "/phone-book",
    component: PhoneBook,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "ServiceAdmin",
      "ServiceCoach",
      "ContractorAdministrator"
    ],
    exact: true
  },
  {
    path: "/exports",
    component: Export,
    allowedRoles: [
      "Administrator",
      "ServiceAdmin",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceCoach",
      "ContractorAdministrator"
    ]
  },
  {
    path: "/support",
    component: Support
  },
  {
    path: "/google-drive",
    component: GoogleDriveFiles,
    allowedRoles: ["Administrator", "SleepCoach"]
  },
  {
    path: "/user/profile",
    component: MyProfile
  },
  {
    path: "/inventory",
    component: InventoryTable,
    exact: true
  },
  {
    path: "/fulfillment-inventory",
    exact: true,
    component: withRouter(() => (
      <FulfillmentInventoryProvider>
        <FulfillmentInventoryTable />
      </FulfillmentInventoryProvider>
    )),
    allowedRoles: ["Administrator"]
  },
  {
    path: "/master-inventory-import",
    component: withRouter(() => (
      <InventoryProvider>
        <FulfillmentInventoryProvider>
          <InventoryImportForm />
        </FulfillmentInventoryProvider>
      </InventoryProvider>
    )),
    exact: true,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/:fulfillmentId/fulfillment-inventory/:inventoryId",
    component: withRouter(() => (
      <FulfillmentInventoryProvider>
        <FulfillmentInventoryProfile />
      </FulfillmentInventoryProvider>
    )),
    exact: true,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/company-inventory-import",
    component: withRouter(() => (
      <InventoryProvider>
        <FulfillmentInventoryProvider>
          <InventoryImportForm />
        </FulfillmentInventoryProvider>
      </InventoryProvider>
    )),
    exact: true,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/fulfillment-inventory-import",
    component: withRouter(() => (
      <InventoryProvider>
        <FulfillmentInventoryProvider>
          <InventoryImportForm />
        </FulfillmentInventoryProvider>
      </InventoryProvider>
    )),
    exact: true,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/crosswalk-inventory-import",
    component: withRouter(() => (
      <InventoryProvider>
        <FulfillmentInventoryProvider>
          <InventoryImportForm />
        </FulfillmentInventoryProvider>
      </InventoryProvider>
    )),
    exact: true,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/inventory/new",
    component: ManageInventory,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/inventory/:id/edit",
    component: ManageInventory
  },
  {
    path: "/inventory/:id",
    component: InventoryProfile,
    exact: true
  },
  {
    path: "/ivr-work",
    component: IVRWorkTableContainer,
    exact: true,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "ServiceCoach",
      "ServiceAdmin",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ContractorAdministrator"
    ]
  },
  {
    path: "/voicemails",
    component: VoicemailsTable,
    exact: true,
    allowedFn: user => user.compliance_coach,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "ServiceAdmin",
      "ServiceCoach",
      "ContractorAdministrator"
    ]
  },
  {
    path: "/voicemails/:id",
    component: VoicemailProfile,
    exact: true,
    allowedFn: user => user.compliance_coach,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "ServiceAdmin",
      "ServiceCoach",
      "ContractorAdministrator"
    ]
  },
  {
    path: "/phones/voicemails/:id",
    component: VMailWorkPage,
    allowedFn: user => user.compliance_coach,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "ServiceAdmin",
      "ServiceCoach",
      "ContractorAdministrator"
    ]
  },
  {
    path: "/phones/voicemails",
    component: VoicemailContainer,
    exact: true,
    allowedFn: user => user.compliance_coach,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "ServiceAdmin",
      "ServiceCoach",
      "ContractorAdministrator"
    ]
  },
  {
    path: "/callrecordings",
    component: CallRecordingContainer,
    exact: true,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "ServiceAdmin",
      "ServiceCoach",
      "ContractorAdministrator"
    ]
  },
  {
    path: "/chats",
    component: ChatsContainer,
    allowedRoles: [
      "Administrator",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceAdmin",
      "ServiceCoach",
      "ContractorAdministrator"
    ]
  },
  {
    path: "/emails",
    component: Email,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/pdf-signatures",
    component: PdfSignatures,
    allowedRoles: [
      "Administrator",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceAdmin",
      "ServiceCoach",
      "ContractorAdministrator"
    ]
  },
  {
    path: "/tasks",
    component: Tasks,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceAdmin",
      "ServiceCoach",
      "ContractorAdministrator"
    ]
  },
  {
    path: "/cmns",
    component: Cmns,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceAdmin",
      "ServiceCoach",
      "ContractorAdministrator"
    ]
  },
  {
    path: "/questionnaires",
    component: Questionnaires,
    allowedRoles: [
      "Administrator",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceAdmin",
      "ServiceCoach",
      "ContractorAdministrator"
    ]
  }
];
