import React, { Component } from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import styler from "react-styling";
import moment from 'moment'
import IoPlay from 'react-icons/lib/io/ios-play';
import IoPause from 'react-icons/lib/io/ios-pause';
import IoRefresh from 'react-icons/lib/io/ios-refresh-empty';

class AudioPlayer extends Component {
    constructor(props) {
        super(props);
        this.audioRef = React.createRef();
        this.myProgressBar = React.createRef();
        this.progressSlider = React.createRef();
        this.state = {
            showPause: false,
            currentTime: 0,
            audioDuration: 0,
            slidingValue: 0,
            progressBarWidth: 0
        };
        this.onAudioPlayerEnded = this.onAudioPlayerEnded.bind(this);
        this.onPlay = this.onPlay.bind(this);
        this.onPause = this.onPause.bind(this);
        this.onAudioPlayerEnded = this.onAudioPlayerEnded.bind(this);
        this.onAudioPlayerReady = this.onAudioPlayerReady.bind(this);
        this.onTimeUpdate = this.onTimeUpdate.bind(this);
        this.onGoSeconds = this.onGoSeconds.bind(this);
    }
    
    onAudioPlayerReady() {
        this.setState({
            ...this.state,
            audioDuration: this.audioRef.current.duration
        });
        if (typeof this.props.onCanPlayThrough === "function") {
            this.props.onCanPlayThrough()
        }
    }
    onPlay() {
        this.setState({
            ...this.state,
            showPause: true
        });
        if (typeof this.props.onPlay === "function") {
            this.props.onPlay()
        }
        const currentTime = this.audioRef.current.currentTime;
        const audioDuration = this.audioRef.current.duration;
        if (audioDuration == 0) {
            if (typeof this.props.onTimeUpdate === "function") {
                this.props.onTimeUpdate(currentTime, audioDuration, true)
            }
        }
    }
    onPause() {
        this.setState({
            ...this.state,
            showPause: false
        });
        if (typeof this.props.onPause === "function") {
            this.props.onPause()
        }
    }
    onAudioPlayerEnded() {
        this.setState({
            ...this.state,
            showPause: false
        });
        if (typeof this.props.onEnded === "function") {
            this.props.onEnded()
        }
    }

    onTimeUpdate() {
        const currentTime = this.audioRef.current.currentTime;
        const audioDuration = this.audioRef.current.duration;
        const sliderLength = this.progressSlider.offsetWidth; //100 pixels currently        
        //Turn percent time into percent width of slider width
        const percentTimeProgressed = currentTime / audioDuration;
        const newProgressBarWidth = sliderLength * percentTimeProgressed;
        this.setState({
            ...this.state,
            currentTime: currentTime,
            progressBarWidth: newProgressBarWidth
        });
        if (typeof this.props.onTimeUpdate === "function") {
            this.props.onTimeUpdate(currentTime, audioDuration)
        }
    }
    onGoSeconds(amount) {
        const currentTime = this.state.currentTime;
        const newTime = currentTime + amount;
        this.audioRef.current.currentTime = newTime;
    }

    render() {
        const {
            showAllFeatures = true,
            showTableFormatted = false,
            src,
            muted,
            autoPlay,
            preloadAudio = "auto"
        } = this.props;

        return (
            <div style={{ maxWidth: "105px" }}>
                {!showTableFormatted && (!this.state.showPause ? (
                    <span title="Play Audio">
                        <IoPlay
                            size={this.props.buttonSize || "25px"}
                            style={S.iconStyle}
                            className="playButton"
                            onClick={() => this.audioRef.current.play()}
                        />
                    </span>
                ) : (
                    <span title="Pause Audio">
                        <IoPause
                            size={this.props.buttonSize || "25px"}
                            style={S.iconStyle}
                            className="pauseButton"
                            onClick={() => this.audioRef.current.pause()}
                        />
                    </span>
                ))}
                {showAllFeatures &&
                    <React.Fragment>
                        <span
                            style={S.timeView}
                            className="durationAndPosition">
                            {
                                moment(this.state.currentTime * 1000).format('mm:ss')
                                + " | "
                                + moment(this.state.audioDuration * 1000).format('mm:ss')
                            }
                        </span>
                        <input
                            title="Seek through audio"
                            style={S.sliderInput}
                            onChange={(e) => this.audioRef.current.currentTime = Number(e.target.value)}
                            type="range"
                            className="progressSlider"
                            ref={(input) => this.progressSlider = input}
                            min={0}
                            step={this.state.audioDuration / 1000}
                            max={this.state.audioDuration}
                            value={this.state.currentTime}
                        >
                        </input>
                        <div className="progressBarBackground">
                            <div style={{ width: this.state.progressBarWidth || 0 }} className="progressBar" ref={this.myProgressBar}></div>
                        </div>
                        <span className="quickAudioMoveContainer">
                            <span
                                title="Go Back 2 seconds"
                                className="goBackSpan"
                                onClick={() => this.onGoSeconds(-2)}>
                                <span className="goBackAmount">2s</span>
                                <span>
                                    <IoRefresh
                                        className="goBackIcon"
                                        style={S.refreshIcon}
                                        size="28px"
                                    />
                                </span>
                            </span>
                            <span
                                title="Go Forward 2 seconds"
                                className="goForwardSpan"
                                onClick={() => this.onGoSeconds(2)}>
                                <span className="goForwardAmount">2s</span>
                                <span>
                                    <IoRefresh
                                        className="goForwardIcon"
                                        size="28px"
                                        style={S.refreshIcon}
                                    />
                                </span>
                            </span>

                        </span>
                    </React.Fragment>
                }
                {showTableFormatted &&
                    <div>
                        <div style={{ display: "flex", width: "100%" }}>
                            <span title={this.state.showPause ? "Pause Audio" : "Play Audio"}>
                                {this.state.showPause ? (
                                    <IoPause
                                        size={this.props.buttonSize || "24px"}
                                        style={STableFormatted.iconStyle}
                                        onClick={() => this.audioRef.current.pause()}
                                    />
                                ) : (
                                    <IoPlay
                                        size={this.props.buttonSize || "24px"}
                                        style={STableFormatted.iconStyle}
                                        onClick={() => this.audioRef.current.play()}
                                    />
                                )}
                            </span>
                            <div style={{ display: "flex" }}>
                                {[-2, 2].map((sec, index) => {
                                    const isBack = sec < 0;
                                    return (
                                        <span
                                            key={index}
                                            title={`Go ${isBack ? "Back" : "Forward"} 2 seconds`}
                                            style={STableFormatted.refreshIconContainer}
                                            onClick={() => this.onGoSeconds(sec)}
                                        >
                                            <IoRefresh
                                                className={isBack ? "goBackIcon" : "goForwardIcon"}
                                                style={STableFormatted.refreshIcon}
                                                size="26px"
                                            />
                                            <span style={STableFormatted.moveSeconds}>
                                                2s
                                            </span>
                                        </span>
                                    );
                                })}
                            </div>
                        </div>

                        <div>
                            <span style={STableFormatted.timeView}>
                                {
                                    moment(this.state.currentTime * 1000).format('mm:ss')
                                    + " | "
                                    + moment(this.state.audioDuration * 1000).format('mm:ss')
                                }
                            </span>
                            <div>
                                <input
                                    title="Seek through audio"
                                    style={STableFormatted.sliderInput}
                                    onChange={(e) => this.audioRef.current.currentTime = Number(e.target.value)}
                                    type="range"
                                    className="progressSlider"
                                    ref={(input) => this.progressSlider = input}
                                    min={0}
                                    step={this.state.audioDuration / 1000}
                                    max={this.state.audioDuration}
                                    value={this.state.currentTime}
                                />
                            </div>

                        </div>

                    </div>
                }
                <audio
                    controls
                    preload={preloadAudio}
                    style={{ display: "none" }}
                    src={src}
                    onCanPlayThrough={() => this.onAudioPlayerReady()}
                    onPlay={() => this.onPlay()}
                    onPause={() => this.onPause()}
                    onTimeUpdate={() => this.onTimeUpdate()}
                    onEnded={() => this.onAudioPlayerEnded()}
                    autoPlay={autoPlay}
                    ref={this.audioRef}
                    muted={muted}
                />
            </div>
        );
    }
}

AudioPlayer.propTypes = {
    preloadAudio: PropTypes.string,
    src: PropTypes.string.isRequired,
    onCanPlayThrough: PropTypes.func,
    onPlay: PropTypes.func,
    onPause: PropTypes.func,
    onTimeUpdate: PropTypes.func,
    onEnded: PropTypes.func,
    muted: PropTypes.bool,
    autoPlay: PropTypes.bool,
    buttonSize: PropTypes.string,
    showAllFeatures: PropTypes.bool,
    showTableFormatted: PropTypes.bool,
};

const S = styler`
  iconStyle
    padding-left: 5;
    position: relative;
    top: 3px;
    cursor: pointer;
    vertical-align: baseline
  timeView
    padding: 5;
    position: relative;
    top: -6px;  
  refreshIcon
    vertical-align: baseline
`;
const STableFormatted = styler`
  iconStyle
    cursor: pointer;
  timeView
    position: relative;
    top: -6px;
  moveSeconds
    font-size: 7px;
    position: absolute; 
    left: 9.5px;
  refreshIconContainer:
    height: 28px; 
    cursor: pointer
    display: flex
    align-items: center
    position: relative
  sliderInput
    width: 100%;
    margin: 0;
    padding: 0;
`;

export default Radium(AudioPlayer);
